import React from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import classNames from 'classnames';

import { useModal } from 'hooks';
import { BLOG_ENDPOINT } from 'constants/endpoints';
import { useIsMounted } from 'hooks';

import { SignOut, SignInLink, SignUpLink } from 'components/Authentication';
import { TripSearch } from 'components/Controls';

import Styled from './styled';

const NavigationTray = ({ className, isLoggedIn }) => {
  const mounted = useIsMounted();
  const dispatch = useDispatch();
  const expanded = useSelector(state => state.navigation.get('expanded'));
  const [openModal, closeModal] = useModal();

  const search = () => {
    dispatch(openModal('COMPONENT', { component: <TripSearch context="top navigation" orientation="vertical" onClose={closeModal} /> }));
  };

  const baseClass = classNames('navigation-tray', className, {
    open: expanded,
  });

  return (
    mounted && (
      <Styled className={baseClass}>
        <ul>
          <li>
            <a href="/">Home</a>
          </li>
          <li>
            <button type="button" className="link" onClick={() => search()}>
              Search
            </button>
          </li>
          <li>
            <a href="/collections">Collections</a>
          </li>
          <li>
            <a href="/destinations">Destinations</a>
          </li>
          <li>
            <a href="/deals/hotel">Deals</a>
          </li>
          {/* <li><a href="/membership">Skylark Plus</a></li> */}
          {isLoggedIn ? (
            <span>
              <li>
                <a href="/account">My Account</a>
              </li>
              <li>
                <SignOut context="top navigation">Sign Out</SignOut>
              </li>
            </span>
          ) : (
            <span>
              <li>
                <SignInLink context="top navigation" id="navigation_sign_in">
                  Log in
                </SignInLink>
              </li>
              <li>
                <SignUpLink context="top navigation" id="navigation_sign_up">
                  Register
                </SignUpLink>
              </li>
            </span>
          )}
          <li>
            <a href={BLOG_ENDPOINT} rel="noopener" target="_blank">
              Inspire&nbsp;Me
            </a>
          </li>
        </ul>
      </Styled>
    )
  );
};

NavigationTray.defaultProps = {
  isLoggedIn: false,
};

NavigationTray.propTypes = {
  className: PropTypes.string,
  isLoggedIn: PropTypes.bool,
};

export default NavigationTray;
